/*import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            
            <div className="container lg:px-0 py-20">
                <Title>Plan iOpos</Title>
                <Text>
                    PLAN TOTAL IOPOS
                    <br />
                    <br />
                    Us presentem un plan renovat fins i tot més complet.
                    <br />
                    <br />
                    Si et prepares per a Mossos d'Esquadra, Guàrdia Urbana i/o policies locals
                    aquest plan és per a tú.
                    <br />
                    <br />
                    Un plan de formació personalitzada on pots tenir tota la formació de iOpos al
                    teu abast.
                    <br />
                    <br />
                    Sí si, has escoltat be! tota la formació durant dotze mesos.
                    <br />
                    <br />
                    A més assessorament i planificació per a aconseguir els teus objectius més
                    ràpid.
                    <br />
                    <br />
                    Què és Plan iOpos?
                    <br />
                    <br />
                    És una tarifa que s'activa des del moment en què et matricules fins passats
                    dotze mesos.
                    <br />
                    <br />
                    Què puc fer amb Plan iOpos?
                    <br />
                    <br />
                    Durant dotze mesos pots utilitzar qualsevol producte nostre.
                    <br />
                    <br />
                    A què tinc accés?
                    <br />
                    <br />
                    Pots agafar qualsevol aplicació, llibre, curs en línia o presencial (en els
                    presencials sense repetir el format).
                    <br />
                    <br />
                    Puc agafar aquesta tarifa entre diversos usuaris?
                    <br />
                    <br />
                    No, aquesta tarifa és personal i intransferible i no pot utilitzar-se en
                    diversos dispositius al mateix temps.
                    <br />
                    <br />
                    En quant està valorat tot el Plan?
                    <br />
                    <br />
                    L'adquisició de tot està valorada en més de 3.000 euros.
                    <br />
                    <br />
                    Exactament, a què puc optar?
                    <br />
                    <br />
                    ▪️ Mòdul d'exàmens oficials
                    <br />
                    <br />
                    ▪️ Mòdul actualitat
                    <br />
                    <br />
                    ▪️ Mòdul de Guàrdia Urbana
                    <br />
                    <br />
                    ▪️ Mòdul de policia local
                    <br />
                    <br />
                    ▪️ Mòdul de Mossos d'Esquadra
                    <br />
                    <br />
                    ▪️ Mòdul de psicotècnics
                    <br />
                    <br />
                    ▪️ Pòdcast
                    <br />
                    <br />
                    ▪️ Curs presencial Mossos d'Esquadra
                    <br />
                    <br />
                    ▪️ Curs presencial Guàrdia Urbana de Barcelona
                    <br />
                    <br />
                    ▪️ Curs en línia de Mossos d'Esquadra 2022-23
                    <br />
                    <br />
                    ▪️ Curs en línia de Guàrdia Urbana 2022-23
                    <br />
                    <br />
                    ▪️ Curs en línia de Policia local
                    <br />
                    <br />
                    ▪️ Qualsevol curs en línia de policies locals
                    <br />
                    <br />
                    ▪️ Curs presencial de psicotècnics aptitudinals
                    <br />
                    <br />
                    ▪️ Curs en línia de psicotècnics aptitudinals
                    <br />
                    <br />
                    ▪️ Curs presencial de test de personalitat
                    <br />
                    <br />
                    ▪️ Preparació de proves físiques dos mesos
                    <br />
                    <br />
                    ▪️ Curs online d'anglès
                    <br />
                    <br />
                    ▪️ Curs presencial de casos pràctics
                    <br />
                    <br />
                    ▪️ Curs en línia de casos pràctics
                    <br />
                    <br />
                    ▪️ Llibres (fins a dos llibres al mes)
                    <br />
                    <br />
                    ▪️ Seminaris
                    <br />
                    <br />
                    ▪️ Classes magistrals
                    <br />
                    <br />
                    ▪️ Abonament de 10 classes de proves físiques a uns dels nostres centres.
                    <br />
                    <br />
                    Amb aquest plan ja no haig de pagar res més?
                    <br />
                    <br />
                    Aquesta és la idea. Amb un únic pagament, que tingueu una formació integral
                    durant dotze mesos.
                    <br />
                    <br />
                    Quant val el Plan iOpos?
                    <br />
                    <br />
                    1.499,99 € fraccionat en tres pagaments a través de PayPal
                    <br />
                    <br />
                    Únic requisit: No repetir cursos de la mateixa formació.
                    <br />
                    <br />
                    El 95% d'alumnes que tenen el plan són aptes.
                    <br />
                    <br />
                    Opositar mai havia estat tan fàcil!
                    <br />
                    <br />
                    Recorda, accés directe al ISPC
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/Dgbwt-mDR00"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
            
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};

export default Product;*/

import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>Et presentem el plan més complet que podràs trobar!</strong>
                </p>
                <p>
                    Si et prepares per a Mossos d'Esquadra, Guàrdia Urbana i/o policies locals
                    aquest plan és per a tú.
                </p>
                <p>
                    Tracta d'un plan de formació personalitzada on pots tenir tota la formació de
                    iOpos al teu abast.
                </p>
                <p>
                    Sí, has escoltat bé! Tota la formació durant dotze mesos, junt amb un
                    assessorament i planificació per a aconseguir els teus objectius més ràpid.
                </p>
                <div>
                    <p>
                        <strong>- Què és Plan iOpos?</strong>
                    </p>
                    <p>
                        És una tarifa plana que s'activa des del moment en què et matricules fins
                        passats dotze mesos.
                    </p>
                </div>
                <div>
                    <p>
                        <strong>- Què puc fer amb Plan iOpos?</strong>
                    </p>
                    <p>
                        Durant dotze mesos pots utilitzar qualsevol producte de la nostra acadèmia.
                    </p>
                </div>

                <div>
                    <p>
                        <strong>- A què tinc accés?</strong>
                    </p>
                    <p>
                        El Plan iOpos és el curs més complet que tenim, en ell tindràs accés a totes
                        les formacions disponibles:
                    </p>
                    <ul className="space-y-2">
                        <li>Cursos Online il·limitats.</li>
                        <li>
                            Cursos Presencials (Entren tots els cursos, però no podràs repetir el
                            mateix dos vegades).
                        </li>
                        <li>
                            T'enviem tots els llibres, podràs demanar fins a dos llibres cada mes
                            (Dins dels 12 mesos que dura el plan).
                        </li>
                        <li>
                            10 sessions per la preparació de proves físiques en l'horari i ubicació
                            que més s'adapti a tu.
                        </li>
                        <li>
                            Accés durant 12 mesos a TOTS els mòduls de les nostres aplicacions:
                            iMossos, iGuB... (inclosos també podcasts i audiollibres).
                        </li>
                        <li>
                            Una sessió de preparació d'entrevista d'1:30h, individual,
                            personalitzada i presencial.
                        </li>
                    </ul>
                </div>

                <p>L'adquisició de tot al que tindràs accés està valorada en més de 3.000 euros.</p>

                <div>
                    <p>
                        <strong>- Puc agafar aquest Plan entre diversos usuaris?</strong>
                    </p>
                    <p>
                        No, aquesta tarifa és personal i intransferible, ja que l'atenció és
                        personalitzada.
                    </p>
                </div>

                <div>
                    <p>
                        <strong>- Amb aquest plan ja no haig de pagar res més?</strong>
                    </p>
                    <p>
                        Aquesta és la idea. Et donem la formació necessària per no haver de pagar
                        cap curs més.
                    </p>
                    <p>
                        En aquest Plan t'oferim formació de TOTES les fases de cada oposició, pel
                        que estaràs preparat per cada una d'elles sense haver de preocupar-te de
                        res!
                    </p>
                </div>

                <div>
                    <p>
                        <strong>- Quant val el Plan iOpos?</strong>
                    </p>
                    <p>El Plan iOpos és la tarifa més completa i econòmica que podràs trobar!</p>
                    <p>
                        El preu d'aquest és de 1.499,99 €, amb la possibilitat de fraccionar
                        l'import en tres quotes a través de PayPal* (499.99€).
                    </p>
                </div>

                <p>
                    Al ser un curs totalment personalitzat i amb accés a tots els cursos i
                    formacions disponibles, fa que any rere any el 95% d'alumnes del curs
                    aconsegueixi el seu <strong>APTE</strong>
                </p>
                <p>Opositar mai havia estat tan fàcil!</p>
                <p>Recorda, accés directe al ISPC.</p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/Dgbwt-mDR00"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Amb el Plan iOpos inverteix en el teu futur i aprofita el 95% d'èxit dels nostres alumnes!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
